.badge {
  border-radius: 8px;
  padding: 0 6px;
  left: calc(50% - 3px);
  top: 8px;
  min-width: 12px;
  font-size: 10px;
  line-height: 17px;
  font-weight: 600;
}
