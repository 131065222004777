
.navButtons{
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.checkboxCard {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(1, auto);
  justify-content: start;
  align-items: center;
  gap: 10px;
  
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &:last-child{
    margin-bottom: 20px;
  }
}

ion-checkbox{
  margin-left: 5px;

}

.title{
  font-size: 18px;
  text-align: center;
  margin: 8px ;
}

.sigCanvas{
  border: 3px dashed gray;
}

.signatureWrapper{
  display: flex;
  flex-direction: row;
  width: 80%;
  margin: 0 auto;
  justify-content: center;

}

.sigImage {
  background-size: 200px 50px;
  width: auto;
  height: 100px;
  background-color: white;
  // border: 1px solid gray;
}

.sigImageWrapper{
  text-align: center;
}

.sigButton{
  display: grid;
  grid-template-columns: repeat(1, auto);
}

.statsButtons{
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
}
.singleStatsButton{
  display: grid;
  grid-template-columns: repeat(1, auto);
}

.openCanvas{
  text-align: center;
}


.title{
  font-size: 18px;
  text-align: center;
  margin: 8px 0;
}
.header{
  font-size: 18px;
}

.signatureFields{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 20px;
}