/* desktop & tablet */

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: stretch;
  margin: 20px 0;
}

aside {
  width: 20%;
  min-width: 186px;
  max-width: 250px;
}

.main {
  width: 80%;
  max-width: var(--max-element-size);
  padding: 20px;
}

/* phone */

.wrapperPhone {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 4px 0 0;
  width: 100%;
  max-width: var(--max-element-size);
  margin: 0 auto;
}