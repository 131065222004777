.registerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  .logo {
    margin-top: 20px;
  }

  .title {
    font-size: 24px;
    font-weight: 400;
  }

  .form {
    width: 100%;

    ion-input {
      height: 63px;
      margin-bottom: 10px;
    }

    .captcha {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0;
    }
  }
}

.successWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  &.mobile {
    border-radius: 20px;
    border: 1px solid var(--ion-color-light-tint);
    box-shadow: 0 10px 18px -10px var(--ion-color-light-tint);
    overflow: hidden;
    padding: 20px 0 0;
  }

  .successTitle {
    font-size: 26px;
    margin: 10px 0 0;
  }

  div {
    p {
      margin: 0;
      text-align: center;
      color: var(--ion-color-medium);

      &:nth-of-type(2) {
        color: var(--ion-color-primary);
      }
    }
  }

}