.pageWrapper {
  --padding-top: 20px;
  --padding-bottom: 20px;
  --padding-end: 20px;
  --padding-start: 20px;
}

.header {
  display: flex;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .greeting {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .infoContainer {
    width: 50%;
    margin-bottom: 0;
  }
}

.infoContainer {
  width: 100%;
  margin-bottom: 20px;

  .swiper {
    width: 100%;
    padding: 5px 0;
  }
}

.pageContent {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.paymentCard,
.actionsCard {
  width: calc(50% - 10px);
  max-width: var(--max-element-size);
  border: 1px solid var(--ion-color-light-tint);
  border-radius: 20px;
  padding: 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
  }
}

.actionsCard {
  @media screen and (max-width: 768px) {
    padding-bottom: 10px;
  }
}