.wrapper {
  margin-top: 4px;
}

.list {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  &:last-child {
    margin-bottom: 10px;
  }
}

.item {
  padding: 6px 10px;
  border: 1px solid var(--ion-color-light);
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 13px;
  font-weight: 300;
  line-height: 1;
  cursor: pointer;
}

.active {
  color: var(--ion-color-tertiary);
  border-color: var(--ion-color-tertiary);
}

.contractsFilter {
  background: white;
  border: 1px solid var(--ion-color-light);
  border-radius: 10px;
  padding: 0 10px;
  margin-top: 14px;

  select {
    height: 40px;
    width: 100%;
    cursor: pointer;
  }

  select, option {
    background: white;
    border: none;
    outline: none;
  }
}