.capitalized {
  text-transform: capitalize;
  margin-left: 3px;
}

.providerBtn {
  ion-spinner, ion-icon {
    margin-right: 8px;
  }

  ion-icon {
    font-size: 28px;
  }
}