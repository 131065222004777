.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 38px;

  /* suprascrie ionic-ul niste chestii foarte ciudat pe aici,
     asta fiid motivul pentru care exista atatea !important-uri */

  ion-button {
    --background: var(--ion-color-primary-contrast) !important;
    --background-activated: var(--ion-color-tertiary) !important;
    --background-hover: var(--ion-color-tertiary) !important;
    --background-focused: var(--ion-color-tertiary) !important;
    --color: var(--ion-color-tertiary) !important;
    --border-color: var(--ion-color-tertiary) !important;
    --border-width: 1px !important;
    --border-radius: 10px !important;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    height: 28px;
    margin: 0;

    &.inactive {
      --background-activated: var(--ion-color-medium) !important;
      --background-hover: var(--ion-color-medium) !important;
      --background-focused: var(--ion-color-medium) !important;
      --border-color: var(--ion-color-light) !important;
      --color: var(--ion-color-medium) !important;
    }

    img {
      width: 20px !important;
      margin-right: 8px !important;
    }
  }
}