
.navButtons{
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

// ion-item {
//   --highlight-color-focused: #43e7f3;

// }



.checkboxCard {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(1, auto);
  justify-content: start;
  align-items: center;
  gap: 10px;
  
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
  &:last-child{
    margin-bottom: 20px;
  }
}

ion-checkbox{
  margin-left: 5px;

}

.textAligned{
  text-align: justify;
}

.title{
  font-size: 18px;
  text-align: center;
  margin: 8px 0;
}

.statsButtons{
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 10px;
}
.singleStatsButton{
  display: grid;
  grid-template-columns: repeat(1, auto);
}

.iconImage{
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed gray;

  ion-icon{
    font-size: 64px !important;

    padding-top: 20px;
  }

}

.uploadText{
  text-align: center;
}

// client type page

.wrapperLabelClient{
  display: flex;
  justify-content: center;
  align-items: center;
}

.labelClient{
  color: var(--ion-color-tertiary);
  padding: 10px 5px;
  cursor: pointer;
}

.chevronIcon{
  font-size: 16px;
  color: var(--ion-color-tertiary);

}

.clientTypeButtons{
  display: grid;
  grid-template-columns: repeat(3, auto);
  padding: 10px 10px;
}
@media (max-width: 575px){
  .clientTypeButtons{
    display: grid;
    grid-template-columns: repeat(1, auto);
    grid-template-rows: repeat(3, auto);

    padding: 10px 10px;
  }
}

.contractTypeButtons {
  display: grid;
  grid-template-columns: repeat(2, auto);
  padding: 10px 10px;
}

.contractSelection{
  text-align: center;
}

// Cerere, pasul 4


.spacedInputs{
  display: grid;
  grid-template-columns: repeat(2, 40%);
  justify-content: center;
  align-items: center;
  gap: 20px;

}


.header{
  font-size: 18px;
}
.subHeader{
  font-size: 16px;
  color: black;
}

.largeInput{
  width: 83%;
  margin: 0 auto;
}

.toggleWrapper{
  display: flex;
  flex-direction: row;
  align-items: center;

  .approvedText{

    color: var(--ion-color-tertiary);
  }
  .toggle{
    display: inline-block;
  }
}

// Structura Inputuri Pagina

//Nume


@media (max-width: 420px){
  .surname{
    display: grid;

  }
  .seriesCI{
    display: grid;

  }

  .spacedInputs{
    display: grid;
    grid-template-columns: repeat(1, 83%);
    gap: 5px;

    // flex-direction: column;
    // align-items: flex-start;

  }
}

// pagina Carte Identitate


.imgWrapper{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;


  .imgCI{
    width: 80%;

  }
  
}

.textCentered{
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.custom-loading{
  --height: auto;
  --width: auto;
  --background: red
}