.mobileSwiper {
  margin-bottom: 10px;

  .swiper-wrapper {

    .swiper-slide {
      width: auto !important;

      & > ion-button {
        --border-radius: 25px;
        --padding-end: 20px;
        --padding-start: 20px;
        width: 100%;
        height: 50px;
        text-align: left;

        &:not([color="tertiary"]) {
          --background: var(--ion-color-light-tint);
          --background-hover: var(--ion-color-light);
          --background-hover-opacity: 0.2;
          --background-activated: var(--ion-color-light);
          --color: var(--ion-color-light-contrast);
        }

        & > ion-text {
          width: 100%;
          font-weight: 600;
          font-size: 14px;
          text-align: center;
        }
      }
    }
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .swiper-button-prev, .swiper-button-next {
    color: var(--ion-color-light-contrast);
    background: white;
    width: 50px;
    height: 60px;
    margin: 0;
    top: 0;

    &::after {
      font-size: 20px;
      font-weight: 800;
    }
  }
}