.chartBox {
  width: 100%;
  text-align: center;
  position: relative;
  box-sizing: border-box;
}

.chart {
  width: 250px;
}

.chartLabel {
  position: absolute;
  left: 50%;
  top:50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-weight: 600;
  color: var(--ion-color-light-contrast);
}

.chartLabelEmpty {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 600;
  color: var(--ion-color-light-contrast);
  width: 50%;
  max-width: 40%;
}

.contractInfo {
  margin-top: 20px;
  color: var(--ion-color-medium);
  border: 1px solid var(--ion-color-light-tint);
  box-shadow: 0 10px 18px -10px var(--ion-color-light-tint);
  border-radius: 20px;
  padding: 10px;
  cursor: pointer;

  .contractDetails {
    --padding-start: 0;
    --inner-padding-end: 0;
    color: var(--ion-color-medium);

    ion-icon {
      font-size: 20px;

      &[slot="start"] {
        margin-right: 14px;
      }

      &[slot="end"] {
        margin-left: 10px;
        transition: transform 0.3s ease-in-out;
      }
    }
  }

  ul {
    display: none;
  }

  &.open {
    .contractDetails ion-icon {
      &:last-child {
        transform: rotate(180deg);
      }
    }

    ul {
      margin: 4px 0 0;
      padding-left: 30px;
      display: block;
    }
  }
}



.wrapper {
  container-type: inline-size;
  display: flex;
  justify-content: center;
  align-items: center;

  ion-button {
    --border-width: 1px;
    --background: var(--ion-color-primary-contrast);
    --border-color: var(--ion-color-tertiary);
    --color: var(--ion-color-tertiary);
    --background-hover: var(--ion-color-tertiary);
    --background-focused: var(--ion-color-tertiary);
    --background-activated: var(--ion-color-tertiary-contrast);
    --border-radius: 10px;
    --color-activated: var(--ion-color-tertiary);
    height: 30px;
    margin: 10px 3px;

 &:nth-child(2) {
      ion-icon {
        color: var(--ion-color-secondary-tint);
      }
    }
  
    &:nth-child(3) {
      ion-icon {
        color: var(--ion-color-tertiary);
      }
    }
  
    &:nth-child(4) {
      ion-icon {
        color: var(--ion-color-primary);
      }
    }
  
    &:nth-child(5) {
      ion-icon {
        color: var(--ion-color-tertiary-shade);
      }
    }
  
    &:nth-child(6) {
      ion-icon {
        color: var(--ion-color-warning-tint);
      }
    }
  
    &:nth-child(7) {
      ion-icon {
        color: var(--ion-color-medium);
      }
    }
  
    &:nth-child(8) {
      ion-icon {
        color: var(--ion-color-danger-tint);
      }
    }

    &.inactive {
      --border-color: var(--ion-color-light);
      --color: var(--ion-color-medium);
      --background-hover: var(--ion-color-medium);
      --background-focused: var(--ion-color-medium);
    }
  }

}
@container (max-width: 442px) {
  .wrapper .list {
    display: grid;
    grid-template-columns: repeat(2, 1fr)
  }
}