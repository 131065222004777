/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/* AQMETER */

:root {
	--max-element-size: 620px;
}

/** Ionic CSS Variables **/
:root {
	/** primary **/
	--ion-color-primary: #00c27c;
	--ion-color-primary-rgb: 0,194,124;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 0,0,0;
	--ion-color-primary-shade: #00ab6d;
	--ion-color-primary-tint: #1ac889;

	/** secondary **/
	--ion-color-secondary: #3dc2ff;
	--ion-color-secondary-rgb: 61,194,255;
	--ion-color-secondary-contrast: #000000;
	--ion-color-secondary-contrast-rgb: 0,0,0;
	--ion-color-secondary-shade: #36abe0;
	--ion-color-secondary-tint: #50c8ff;

	/** tertiary **/
	--ion-color-tertiary: #007CEF;
	--ion-color-tertiary-rgb: 0,124,239;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255,255,255;
	--ion-color-tertiary-shade: #4854e0;
	--ion-color-tertiary-tint: #1a89f1;

	/** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45,211,111;
	--ion-color-success-contrast: #000000;
	--ion-color-success-contrast-rgb: 0,0,0;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	/** warning **/
	--ion-color-warning: #ffc409;
	--ion-color-warning-rgb: 255,196,9;
	--ion-color-warning-contrast: #000000;
	--ion-color-warning-contrast-rgb: 0,0,0;
	--ion-color-warning-shade: #e0ac08;
	--ion-color-warning-tint: #ffca22;
	--ion-color-warning-darker: #8f6d03;

	/** danger **/
	--ion-color-danger: #F60B44;
	--ion-color-danger-rgb: 246,11,68;
	--ion-color-danger-contrast: #ffffff;
	--ion-color-danger-contrast-rgb: 255,255,255;
	--ion-color-danger-shade: #d80a3c;
	--ion-color-danger-tint: #ed576b;

	/** dark **/
	--ion-color-medium: #283931;
	--ion-color-medium-rgb: 40,57,49;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255,255,255;
	--ion-color-medium-shade: #23322b;
	--ion-color-medium-tint: #3e4d46;

	/** medium **/
	--ion-color-medium: #63768b;
	--ion-color-medium-rgb: 99,118,139;
	--ion-color-medium-contrast: #ffffff;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	/** light **/
	--ion-color-light: #DDE1E7;
	--ion-color-light-rgb: 221,225,231;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
}