.backBtn {
  --border-radius: 20px !important;
  --background: var(--ion-color-light-tint) !important;
  --background-hover: var(--ion-color-light) !important;
  --background-hover-opacity: 0.2 !important;
  --background-activated: var(--ion-color-light) !important;
  --color: var(--ion-color-light-contrast) !important;
  --padding-end: 20px !important;
  --padding-start: 20px !important;
  height: 40px !important;

  ion-label {
    font-weight: 600;
    font-size: 14px;
    margin-left: 6px;
  }
}