.contractDetails {
  padding: 20px;
  border: 1px dashed var(--ion-color-light);
  border-radius: 20px;
  margin-bottom: 10px;

  p {
    font-size: 15px;
    font-weight: 400;
    margin: 0;

    &:nth-of-type(2),
    &:nth-of-type(4) {
      font-weight: 500;
      text-transform: capitalize;
    }

    &:nth-of-type(even) {
      color: var(--ion-color-medium);
      font-size: 14px;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
}

.isInactive {
  position: relative;
  color: var(--ion-color-warning);
  margin-bottom: 10px;

  ion-icon {
    --ionicon-stroke-width: 50px;
    font-size: 18px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.contactInfo {
  h3 {
    margin-top: 10px;
  }

  ion-input {
    height: 42px;
    --padding-start: 0;
    --padding-end: 0;
    margin-top: 4px;

    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }
}

.invoiceType {
  .electronicInvoiceBox {
    ion-item {
      --padding-start: 0;
      --inner-padding-end: 0;

      &.notice {
        color: var(--ion-color-medium);
        text-align: center;
        font-size: 12px;
        font-style: italic;
        --min-height: 28px;
      }

      ion-checkbox {
        margin: 10px 10px 10px 4px;
      }

      ion-label {
        opacity: 0.5;
        color: var(--ion-color-medium);

        &.checked {
          opacity: 1;
          color: var(--ion-color-light-contrast);
        }
      }
    }
  }
}

.electronicInvoiceEmails {
  margin-bottom: 10px;

  .margin {
    margin-bottom: 10px;
  }

  .notice {
    font-size: 10px;
    font-style: italic;
    margin-top: 0;
    margin-bottom: 8px;
    margin-left: 8px;
    margin-right: 0;
    text-align: left;
  }

  ion-chip {
    padding-top: 6px;
    padding-right: 4px;
    width: 100%;
    margin: 0;

    .anchorValidate {
      margin-right: 4px;

      .validate {
        padding: 0 6px;
        font-size: 12px;
        height: 100%;
        width: 80px;

        ion-label {
          text-align: center;
        }
      }
    }

    // &:not(:last-of-type) {
    //   margin-bottom: 8px;
    // }

    // &[color='success'] {
    //   padding-right: 8px;
    // }

    ion-label {
      width: 100%;
    }

    ion-input {
      --padding-start: 0 !important;
      --padding-end: 0 !important;
      border: none !important;
      color: inherit !important;
      background: transparent !important;
      font-size: 13px !important;
    }

    ion-icon {
      font-size: 24px;
    }
  }
}

.settings {
  .settingsTitle {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    cursor: pointer;

    span {
      font: inherit;
      display: inline-block;
      width: calc(100% - 24px - 16px - 20px);
    }

    ion-icon {
      &:first-child {
        font-size: 24px;
        color: var(--ion-color-tertiary);
      }

      &:last-child {
        font-size: 30px;
        transition: transform 0.3s ease-in-out;
      }
    }

    &.open {
      ion-icon {
        &:last-child {
          transform: rotate(180deg);
        }
      }
    }
  }

  .settingsWrapper {
    display: none;

    &.open {
      display: block;
    }
  }

  .settingsRow {
    h4 {
      margin: 4px 0 0;
    }

    .settingsRowContent {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: 10px;
      align-items: center;

      ion-item {
        --padding-start: 0;
        --inner-padding-end: 8px;

        ion-checkbox {
          margin: 8px 10px 8px 8px;
        }
      }
    }
  }
}

.twoFactorForm {
  width: 370px;

  @media screen and (max-width: 400px) {
    width: auto;
  }
}

.twoFactorInputs {
  input {
    width: 100%;
    height: 60px;
    border: 1px solid var(--ion-color-light) !important;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 15px;
    color: var(--ion-color-medium);
    padding: 0 20px;
    outline: none;

    &:focus {
      border-color: var(--ion-color-tertiary) !important;
    }
  }
}

.titleContainer {
  width: 100%;
  text-align: center;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;

  .loaderText {
    display: flex;
    flex-direction: column;
  }
}

.pinInput {
  width: 100%;
  margin-top: 5px;
  text-align: center;
}

.linkResend {
  margin: 0 auto;
}

.validityText {
  margin-bottom: 5px;
}

.progressBar {
  width: 60%;
  margin: 0 auto;
}

.loaderWrapper {
  margin: 0 auto;
  width: 60%;
}

.loader-progressBar {
  width: 100%;
  height: 4.8px;
  display: inline-block;
  position: relative;
  background: rgba(var(--ion-color-tertiary-rgb), 0.1);
  overflow: hidden;
}

.loader-progressBar::after {
  content: '';
  box-sizing: border-box;
  width: 0;
  height: 4.8px;
  background: var(--ion-color-tertiary);
  position: absolute;
  top: 0;
  left: 0;
  animation: animFw 300s linear;
  animation-fill-mode: forwards;
}

@keyframes animFw {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
