.invoice {
  padding: 10px 0;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  width: 100%;

  img {
    width: 40px;
  }

  p {
    margin: 0;
  }

  .details {
    flex: 1;

    p:first-child {
      font-size: 15px;
    }

    p:nth-child(2) {
      text-transform: capitalize;
    }
  }

  .status {
    text-align: right;
    flex-basis: auto;

    p:first-child {
      color: var(--ion-color-primary);
      font-size: 13px;
    }

    p:not(:first-child) {
      font-weight: 600;
      font-size: 14px;
    }
  }
}

.actions {
  margin-top: 2px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  a {
    color: var(--ion-color-tertiary);
    cursor: pointer;
  }
}