.pageTitle {
  font-weight: 400;
  font-size: 24px;
  display: block;
  margin-bottom: 20px;
}

.form {
  ion-input {
    margin: 6px 0;
  }

  .passWrapper {
    position: relative;

    ion-icon {
      font-size: 24px;
      position: absolute;
      right: 26px;
      bottom: 10px;
      z-index: 100;

      &.off {
        color: var(--ion-color-medium);
      }

      &.on {
        color: var(--ion-color-tertiary);
      }
    }

    .passInput {
      --padding-end: 60px;
      font-style: normal !important;
    }
  }

  ion-button {
    margin-top: 20px;
  }
}

.checkmark {
  font-size: 24px;
}

// Poză Profil

.avatarImageWrapper {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  .avatarImage{
    cursor: pointer;
    width: 8rem;
    height: 8rem;
  }

  .avatarLabel{
    cursor: pointer;
    color: var(--ion-color-tertiary);
  }
}

  .avatarLabelCancel{
    cursor: pointer;
    color: var(--ion-color-danger)
  }