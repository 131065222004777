.payInit {
  background: var(--ion-color-warning);
  margin: 10px 6px 0;
  padding: 10px;
  border-radius: 10px;

  ion-label {
    color: white;
  }
}

.balanceInfo {
  border: 1px solid var(--ion-color-tertiary);
  border-radius: 10px;
  margin: 10px 6px 0;
  position: relative;
  padding: 10px 36px 10px 20px;
  color: var(--ion-color-tertiary);

  ion-icon {
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .accent {
    color: var(--ion-color-primary);
  }
}

.buttonsGrid {
  width: 100%;
}