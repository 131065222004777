.passWrapper {
  position: relative;

  .passInput {
    --padding-end: 60px;
    font-style: normal !important;
  }

  ion-icon {
    font-size: 24px;
    position: absolute;
    right: 26px;
    bottom: 10px;
    z-index: 100;

    &.off {
      color: var(--ion-color-medium);
    }

    &.on {
      color: var(--ion-color-tertiary);
    }
  }

  &.large {
    .passInput {
      height: 63px;
    }

    ion-icon {
      bottom: 20px;
    }
  }
}