.stepsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.step {
  background: transparent;
  width: 30px;
  height: 30px;
  border: 1px solid var(--ion-color-tertiary);
  border-radius: 50%;
  font-weight: 600;
  color: var(--ion-color-tertiary);
  padding: 1px;

  &:disabled {
    color: var(--ion-color-medium);
    border-color: var(--ion-color-light);
  }


  &.active {
    background: var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
    color: var(--ion-color-tertiary-contrast);
  }
}

.stepLabel{
  display: flex;
  grid-template-columns: repeat(2, auto);
  grid-template-rows: repeat(1, auto);
  align-items: center;
  gap: 3px;
}

.activeText{
  color: var(--ion-color-tertiary);
}

.activeDisabled{
  display: none;
  // color: transparent;
}