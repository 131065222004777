.wrapper {
  margin: 0 12px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  ion-item {
    --padding-start: 4px;
    --inner-padding-end: 0;
  }

  ion-select::part(placeholder),
  ion-select::part(text),
  ion-select::part(icon) {
    color: var(--ion-color-medium);
    opacity: 1;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  ion-checkbox {
    --border-width: 2px;
    --border-color: var(--ion-color-medium);

    &[checked="true"] {
      --border-width: 6px;
    }
  }
}

.markRead, .delete, .selectAll {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: var(--ion-color-medium);
  width: 40px;
  height: 40px;
  background: rgba(var(--ion-color-light-rgb), 0.5);
  border-radius: 10px;
}

.markRead {
  ion-icon {
    font-size: 26px;
  }

  &.active {
    background: rgba(var(--ion-color-primary-rgb), 0.1);
    color: var(--ion-color-primary);
  }
}

.delete {
  ion-icon {
    font-size: 22px;
  }

  &.active {
    background: rgba(var(--ion-color-danger-rgb), 0.1);
    color: var(--ion-color-danger);
  }
}