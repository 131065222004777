.wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 30px);
  width: 100%;
}

.actionsList {
  width: 100%;

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;

    ion-icon[slot="end"] {
      font-size: 20px;
      color: var(--ion-color-light-contrast);
    }
  }

  ion-avatar[slot="end"] {
    width: 20px;
    height: 20px;
    margin-inline-start: 10px;
  }

  .textBox {
    width: 100%;
    padding: 15px 0;

    span {
      display: block;

      &.ag-number-description {
        font-size: 12px !important;
      }
    }
  }
}

.actionsIcon {
  width: 40px;
  height: 40px;
  background: #e9fff2;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  ion-icon {
    font-size: 24px;
    color: var(--ion-color-primary);
  }
}