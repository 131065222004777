.group {
  margin-top: 10px;
}

.title {
  color: var(--ion-color-medium);
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.item {
  &:not(:first-of-type) {
    border-top: 1px solid var(--ion-color-light);
  }
}