.lastReadingsWrapper {
  border: 1px dashed var(--ion-color-light);
  border-radius: 20px;
  padding: 20px;

  .lastReadingsEmpty {
    font-weight: 500;
    color: var(--ion-color-medium);
    display: block;
    text-align: center;
    margin: 10px 0;
  }

  .lastReadingsTitle {
    font-size: 15px;
    font-weight: 400;
    display: block;
    margin-bottom: 10px;
  }
}

.readingLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border: 1px dotted rgba(var(--ion-color-medium-rgb), 0.7);
  border-radius: 10px;
  padding: 4px 8px;

  &:not(:last-of-type) {
    margin-bottom: 8px;
  }

  &.canEdit {
    color: var(--ion-color-tertiary);
    border-color: var(--ion-color-tertiary);
    cursor: pointer;

    &:hover {
      background: rgba(var(--ion-color-tertiary-rgb), 0.1);
    }

    .readingDate {
      color: var(--ion-color-tertiary);
    }
  }

  span {
    font-family: "Azeret Mono", monospace !important;
    font-size: 13px;
  }

  .readingDate {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: var(--ion-color-medium);
    gap: 4px;

    ion-icon {
      font-size: 18px;
    }
  }
}

.editInfo {
  margin: -4px 0 4px 2px;
  font-size: 12px;
  font-style: italic;
  color: var(--ion-color-tertiary);
}

.meterSelector {
  ion-select {
    width: 100%;
  }

  ion-select::part(placeholder),
  ion-select::part(text),
  ion-select::part(icon) {
    color: var(--ion-color-primary);
    opacity: 1;
  }
}

.readingInterval {
  width: calc(100% - 20px);
  margin: 14px 10px 10px;
}

.addReading {
  margin: 10px;

  ion-input {
    margin: 10px 0;

    &.edit {
      border: 1px solid var(--ion-color-tertiary);
      --placeholder-color: var(--ion-color-tertiary);
      //--placeholder-opacity: .8;
    }
  }

  ion-button {
    &[disabled] {
      --background: var(--ion-color-light);
      --color: var(--ion-color-medium);
    }

    &[disabled="false"] {
      --background: var(--ion-color-primary);
      --color: var(--ion-color-primary-contrast);
    }
  }

}

