.paymentFlagBox {
  width: 100%;
  margin-top: 6px;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-weight: 400;
}

.flagItem {
  position: relative;
  border-radius: 5px;
  padding: 4px 8px 4px 26px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 9px;
  }

  &.processing {
    border: 1px solid var(--ion-color-primary);

    &::before {
      background: var(--ion-color-primary);
    }
  }

  &.initialized {
    border: 1px solid var(--ion-color-warning);

    &::before {
      background: var(--ion-color-warning);
    }
  }
}

.paymentLegendBox {

  div {
    margin-top: 10px;
  }

  .flagItem {
    margin-right: 6px;
    padding: 2px 8px 2px 26px;

    &::before {
      top: 6px;
    }
  }
}

.legendTitle {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.legendText {
  color: var(--ion-color-medium);

  &::first-line {
    line-height: 130px;
  }
}