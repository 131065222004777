ion-tab-bar {
  --color-selected: #3880ff;

  ion-badge {
    border-radius: 8px;
    padding: 0 6px;
    left: calc(50% - 3px);
    top: 8px;
    min-width: 12px;
    font-size: 10px;
    line-height: 17px;
    font-weight: 600;
  }
  
  &[slot="bottom"] {
    height: 90px;
    --color: #63768B;

    ion-tab-button {
      --padding-start: 6px;
      --padding-end: 6px;

      .icon-bg {
        width: 30px;
        height: 30px;
        background: transparent;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;

        ion-icon {
          font-size: 16px;
        }
      }

      &.tab-selected {
        .icon-bg {
          background: #3880ff;
          ion-icon {
            color: white;
          }
        }
      }
    }
  }

  &[slot="top"] {
    height: 80px;
    --color: black;
    align-items: center;

    ion-badge {
      left: calc(50% + 6px);
    }

    ion-tab-button {
      &.main-menu-logo-btn {
        margin-left: 15px;
        margin-right: auto;
        min-width: 122px;
      }
      &.small-btns {
        max-width: 50px;
      }
      &.main-menu-bell-btn{
        margin-left: auto;
        position: relative;
        .new-notification {
          left: -2px;
          top: 18px;
        }
      }
      &.main-menu-more-btn {
        margin-right: 15px;
      }
      &.profileAvatar-btn {
        margin-right: 15px;
        max-width: 70px;

      }

      //&.profileAvatar-btn::part(native){
      //  width: 80px;
      //}
      ion-icon {
        font-size: 24px;
        --ionicon-stroke-width: 42px;
      }

      &.main-menu-links.tab-selected {
        border-bottom: 3px solid #3880ff;
      }
    }
  }
}