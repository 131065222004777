/* LOGIN PAGE */

.loginPage {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  min-height: 100vh;
  width: 100%;

  .loginFormWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    text-align: center;
    width: 100%;

    .loginFormTop {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img {
        margin: 10px auto;
      }
    }

    .loginFormBottom {
      color: var(--ion-color-medium);
      padding: 10px 0;

      ion-label {
        font-weight: 400;
        font-size: 12px;
      }

      .storeLinks {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        gap: 10px;

        img {
          height: 46px;
          width: auto;
          @media screen and (max-width: 500px) {
            height: 36px;
          }
        }
      }
    }
  }

  .businessInfo {
    background-size: cover !important;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;

    .businessText {
      padding: 0 70px 70px;
      width: 100%;

      ion-label {
        color: white;
        display: block;

        &:first-of-type {
          font-size: 35px;
          line-height: 52px;
        }

        &:not(:first-of-type) {
          font-weight: 300;
        }
      }
    }
  }

  .businessHeader {
    width: calc(100% - 20px);

    ion-toolbar {
      --background: transparent;
      margin-bottom: 20px;
    }
  }


  &.phone {
    min-height: calc(100vh - 120px);

    .businessHeader {
      display: none;
    }
  }

  &.tablet {
    flex-direction: column-reverse;

    .loginFormWrapper {
      height: 540px;
      padding-top: 30px;
    }

    .businessInfo {
      height: calc(100vh - 540px);

      .businessText {
        padding: 0 30px 30px;
      }
    }

    .businessHeader {
      &.ios {
        margin-top: 20px;
      }
    }
  }

  &.desktop {
    .loginFormWrapper {
      max-width: 600px;
      min-width: 400px;
      width: 40%;
    }

    .businessInfo {
      max-width: calc(100vw - 400px);
      min-width: calc(100vw - 600px);
      width: 60%;

      .businessText {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        text-align: left;
      }
    }

    .businessHeader {
      &.ios {
        margin-top: 20px;
      }
    }
  }
}

/* LOGIN CARD */

.loginForm {
  padding: 0 10px;
  height: 100%;

  form {
    width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    @media screen and (max-width: 400px) {
      width: auto;
    }
  }

  input {
    width: 100%;
    height: 60px;
    border: 1px solid var(--ion-color-light) !important;
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 15px;
    color: var(--ion-color-medium);
    padding: 0 20px;
    outline: none;

    &:focus {
      border-color: var(--ion-color-tertiary) !important;
    }
  }

  ion-item {
    --padding-start: 0;
    margin-top: 4px;
  }

  ion-button {
    width: 100%;

    &[size='small'] {
      height: 30px;
    }
  }
}

.password {
  position: relative;
  width: 100%;

  input {
    padding-right: 60px;
  }

  ion-icon {
    font-size: 24px;
    position: absolute;
    right: 20px;
    top: 18px;
    z-index: 100;
  }
}

.off {
  color: var(--ion-color-medium);
}

.on {
  color: var(--ion-color-tertiary);
}

/* LOGIN SOCIAL BUTTONS */

.ssoTitle {
  color: var(--ion-color-medium);
  font-size: 13px;
  text-align: center;
}

.ssoLogos {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-bottom: 10px;

  ion-icon {
    font-size: 40px;
    cursor: pointer;

    &:nth-of-type(1) {
      color: #1773ea; // facebook
    }

    &:nth-of-type(2) {
      color: #ea4335; // google
    }

    &:nth-of-type(3) {
      color: #666; // apple
    }

    &:nth-of-type(4) {
      color: #0073b1; // linkedin
    }
  }
}




