.statusRequestsAvailable{
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
  font-weight: 400;
}

//  // Card in statusPage

//  .cardWrapper{
//   display: flex;
//   flex-direction: row;

//  }

//  .hyperLinkWrapper{
//   display: flex;
//   flex-direction: column;
//  }


 .cardHeader{
  width: 10px;
 }

//  .success{
//   background-color: var(--ion-color-primary);
// }
//  .warning{
//     background-color: var(--ion-color-warning)
//  }

//  .error{
//   background-color: var(	--ion-color-danger-shade)
// }

// .sent{
//   background-color: var(	--ion-color-medium)
// }

 
 .cardFooter{
  display: flex;
  // height: 150px;
  justify-content: space-between;
  padding: 10px 5px;

 }
 .logoPlusStatusDetailsWrapper{
  display: flex;

 }

 .shadowRootItem{
  ion-item::part(native){
    padding: 0;
    --inner-padding-end: 0;
  
   }
 }

//  .companyStatus{
//   display: flex;
//   flex-direction: column;
//   align-self: center;
//  }

 .companyImage{
  align-self: center;
  width: 60px;
  height: 30px;
  margin: 0 19px;
  img{
    width: 50px;
    height: 40px;
    border-radius: 0;

  }
 }

// //  .successBorderImage{
// //   border: 2px solid var(--ion-color-primary);
// // }
// //  .warningBorderImage{
// //   border: 2px solid var(--ion-color-warning); 
// //  }

// //  .errorBorderImage{
// //   border: 2px solid var(	--ion-color-danger-shade); 
// // }

// // .sentBorderImage{
// //   border: 2px solid var(	--ion-color-medium); 
// // }

 .statusIconConfig{
  font-size: 25px;
  text-align: center;
 }

 .statusText{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 }

 .cancelButton{
  font-size: 12px;
  font-weight:450;
  height: 30px;
 }

 .listWrapper{
  margin: 20px 0;
  ion-item{
    --border-style: none;
  }

 }
 .demandItemsList{
  margin-bottom: 10px;
 }

 .createDemandButtonWrapper{
  display: grid;
  font-weight: 700;
 }

 .splitFooter{
  width: 100%;
 }

 .statusInfo{
    padding-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
  
 }
 .iconChevronDown{
  font-size: 20px;   
  transition: transform 0.3s ease-in-out;

  }

.open {
    transform: rotate(180deg);
}

.bottomTextInfo {
  margin: 4px;
  display: block;
  padding: 0 25px;
}

ion-modal{
  --width: 90%;
  --max-width:500px ;
  --height: 50%;
  --max-height: 350px;
  --margin: 0 20px;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  --backdrop-opacity: 0.3
}

.noDemandText{
  color: var(--ion-color-medium);
  display: flex;
  justify-content: center;
  text-align: center;

}

.documentLinks{
  display: inline-block;
  color: var(--ion-color-tertiary);
  text-decoration: none;
  border-right: 1px solid black;
  padding: 0 5px;
}
.documentLinks:last-child{
  border-right: none;

}
.documentLinks:first-child{
  padding-left: 0;
}

.documentLinksPhone{
  display: inline-block;
  color: var(--ion-color-tertiary);
  text-decoration: none;
  margin: 3px 0;
}

.linksWrapperPhone{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.documentLinksPhoneLeft{
  display: inline-block;
  color: var(--ion-color-tertiary);
  text-decoration: none;
  margin: 3px 0;
}

.linksWrapperPhoneLeft{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}