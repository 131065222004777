.empty {
  text-align: center;
  color: var(--ion-color-medium);
  font-size: 14px;
}

.group {
  margin-top: 10px;
}

.groupTitle {
  color: var(--ion-color-medium);
  margin: 0;
  font-weight: 500;
  text-transform: capitalize;
}

.reading {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  &:not(:first-of-type) {
    border-top: 1px solid var(--ion-color-light);
  }

  img {
    width: 40px;
  }

  p {
    margin: 0;
  }

  .details {
    width: 100%;
    padding-left: 10px;

    p:first-child {
      font-size: 15px;
    }

    p:not(:first-child) {
      font-weight: 300;
    }

    p:nth-child(2) {
      text-transform: capitalize;
    }
  }

  .status {
    text-align: center;
    width: auto;

    p {
      font-size: 11px;

      &:first-child {
        color: var(--ion-color-medium);
      }
    }
  }

  .strong {
    font-size: 14px;
    font-weight: 600;
  }

  p.primary, p.warning {
    font-size: 12px;
  }

  .primary {
    color: var(--ion-color-primary);

  }

  .warning {
    color: var(--ion-color-warning-shade);
  }
}