.title, .subtitle {
  display: block;
  margin: 10px 0;
  text-align: center;
  font-weight: 400;
}

.title {
  font-size: 24px;
}

.subtitle {
  font-size: 15px;
  color: var(--ion-color-medium);
}

.companyItem {
  padding: 15px 0;
  border-radius: 20px;
  border: 1px solid var(--ion-color-light-tint);
  box-shadow: 0 10px 18px -10px var(--ion-color-light-tint);
  margin-bottom: 10px;

  &:hover {
    cursor: pointer;
  }

  ion-label.companyName {
    font-size: 15px;
    font-weight: 400;
  }
}

.verificationForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 6px;

  ion-input {
    position: relative;
    --padding-end: 44px;

    ion-icon {
      position: absolute;
      right: 10px;
      font-size: 24px;
    }
  }

  ion-button {
    margin: 10px 0;

    &[disabled] {
      --background: var(--ion-color-light-tint);
      --color: var(--ion-color-medium);
    }

    &[disabled="false"] {
      --background: var(--ion-color-primary);
      --color: var(--ion-color-primary-contrast);
    }
  }
}

.contractDetails {
  padding: 20px;
  border: 1px dashed var(--ion-color-light);
  border-radius: 20px;
  margin-top: 10px;

  p {
    margin: 0;
    font-size: 15px;
    color: var(--ion-color-medium);
    &:not(:last-of-type) {
      margin-bottom: 6px;
    }
  }
}