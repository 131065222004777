.publicMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: auto;

  div {
    color: var(--ion-color-medium);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    cursor: pointer;

    &:hover {
      color: var(--ion-color-tertiary);
    }

    ion-icon {
      font-size: 19px;
    }

    span {
      font-size: 13px;
    }
  }
}