ion-skeleton-text {
  --border-radius: 6px;
}

ion-modal {
  --backdrop-opacity: 0.85;

  @media only screen and (min-width: 768px) {
    --border-radius: 20px;
  }

  ion-header {
    ion-title {
      --color: var(--ion-color-light-contrast);
      font-size: 18px;
    }

    ion-icon {
      color: var(--ion-color-tertiary);
      --ionicon-stroke-width: 50px;
    }
  }

  ion-content {
    --padding-bottom: 20px;
    --padding-end: 20px;
    --padding-start: 20px;
    --padding-top: 10px;
  }
}

ion-header {
  ion-toolbar {
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-end: 20px;
    --padding-start: 20px;

    @media screen and (max-width: 400px) {
      --padding-end: 10px;
      --padding-start: 10px;
    }
  }
}

ion-checkbox {
  --size: 24px;
  --border-color-checked: transparent;
  --border-width: 1px;
  --border-color: var(--ion-color-light);

  &[checked="true"] {
    --border-width: 6px;
  }

  &[checked="false"] ~ ion-label {
    --color: var(--ion-color-medium) !important;
  }

  &[slot="start"] {
    margin-right: 10px;
  }

  & ~ label {
    font-weight: 500;
    font-size: 11px;
    color: var(--ion-color-medium);

    a {
      font-size: inherit;
    }
  }
}

ion-icon.chevron {
  margin-left: 6px;
  font-size: 20px;
  color: var(--ion-color-light-contrast);
}

ion-icon.profile-thumbnail {
  background: #3880ff;
  border-radius: 100px;
  padding: 3px;
  font-size: 24px;
  color: white;

}

ion-toggle.ios.twoFAToggle {
  padding-right: 0;
}

.main-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loader-parent{
  position: relative;
}

.overlay-spinner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #FFF;
  z-index: 999;

  display: flex;
  justify-content: center;
  align-items: center;
  //width: 100%;
  //height: 100vh;
}

ion-loading.custom-loading {
  --background: rgba(var(--ion-color-tertiary-rgb),0);
  --spinner-color: var(--ion-color-primary);
  --backdrop-opacity: 0.4;
  .loading-wrapper {
    box-shadow: none;
    --webkit-box-shadow:  none;
  }



  color: var(--ion-color-primary);
}

.avatarImage{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  margin-left: 10px;
  position: relative;
}
