.wrapper {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}

.title {
  display: block;
  margin: 20px 0 20px 4px;
  font-weight: 500;
  font-size: 18px;
}

.notificationsBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  .notification {
    border: 1px solid var(--ion-color-light-tint);
    border-radius: 20px;
    padding: 20px;
    width: 33%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 10px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    .notificationTitle {
      font-size: 14px;
      font-weight: 600;
      color: var(--ion-color-light-contrast);

      &.read {
        font-weight: 400;
      }
    }

    .notificationBody {
      margin: 0;
      font-weight: 300;
      font-size: 13px;
      line-height: 19px;
      text-align: left;
    }

    .read {
      color: var(--ion-color-medium);
    }
  }
}