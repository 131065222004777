@import url("https://fonts.googleapis.com/css2?family=Azeret+Mono&display=swap");

/* INPUT AUTOFILL FIX */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid white;
  -webkit-text-fill-color: var(--ion-color-medium);
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

/* GLOBAL APP */

ion-thumbnail {
  --size: 24px;
}

ion-header {
  ion-item {
    --background: transparent;
  }
}

h3 {
  font-size: 18px;
}

h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.center {
  text-align: center;
  width: 100%;
}

ion-text {
  display: block;
  text-align: center;
  margin: 10px 0;
  font-size: 12px;
}

ion-list[lines="full"] {
  ion-item:last-of-type {
    --border-width: 0;
  }
}

.warning-color {
  color: var(--ion-color-warning-shade);
}

.danger-color {
  color: var(--ion-color-danger);
}

.primary-color {
  color: var(--ion-color-primary);
}

.tertiary-color {
  color: var(--ion-color-tertiary);
}

.ellipse {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}

.lowercase {
  text-transform: lowercase !important;
}

.card-widget.top-10 {
  margin-top: 10px !important;
}

.top-20 {
  margin-top: 20px;
}

.max-element-width {
  max-width: var(--max-element-size);
}

ion-avatar.mobile-header-bell-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  padding-left: 10px;
  position: relative;

  .new-notification {
    left: 2px;
    top: 10px;
  }

  ion-icon {
    font-size: 24px;
    --ionicon-stroke-width: 42px;
  }
}

.new-notification {
  position: absolute;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #f60b44;
  background-clip: padding-box;
  border: 3px solid rgba(246, 11, 68, 0.15);
}

.footer-menu {
  list-style-type: none;
  font-family: "Poppins", sans-serif !important;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 10px;

  li {
    border-left: 1px solid #63768b;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;

    &:first-child {
      border-left: none;
    }
  }

  li:hover {
    cursor: pointer;
  }
}

.aq-container-detail {
  padding: 20px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  gap: 20px;

  .aq-container-sidebar {
    width: 20%;
    min-width: 160px;
    max-width: 250px;
  }

  .aq-container-content {
    width: 80%;
    max-width: var(--max-element-size);
    border: 1px solid var(--ion-color-light-tint);
    box-shadow: 0px 10px 18px -10px var(--ion-color-light-tint);
    border-radius: 20px;
    padding: 20px;
  }
}

.layout--phone ion-content {
  --padding-start: 10px;
  --padding-end: 10px;
  --padding-top: 10px;
  --padding-bottom: 10px;
}

.lang-switcher-right {
  width: calc(100vw - 50px);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.layout--tablet .lang-switcher-right,
.layout--desktop .lang-switcher-right {
  width: calc(var(--max-element-size) - 50px);
  margin-top: 20px;
}

.auth-action-btns {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .auth-action-inner {
    color: #63768b;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    &:hover {
      color: #3880ff;
    }

    ion-icon {
      font-size: 20px;
      margin-right: 4px;
    }
  }
}

/* toasts NOT USED ANYMORE */

.toast-wrapper {
  box-sizing: border-box;
  max-width: var(--max-element-size);
  margin: 10px 10px;
  padding: 5px 10px;
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  .toast-icon-bg {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 10px;
  }

  &.slider {
    box-shadow: none;
    max-width: 100%;
  }

  .toast-close-btn {
    width: 24px;
    height: 40px;
    padding: 15px;
    color: inherit;
  }

  .fadeout {
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
  }

  ion-icon {
    font-size: 24px;
    color: inherit;
  }

  &.success,
  &.error {
    color: white;

    .toast-icon-bg {
      background: rgba(255, 255, 255, 0.3);
    }
  }

  &.info {
    background: white;
    color: var(--ion-color-tertiary);
    border: 1px solid var(--ion-color-tertiary);
  }

  &.success {
    background: var(--ion-color-primary);
  }

  &.error {
    background: var(--ion-color-danger);
  }
}

/* COOKIES */

.react-cookie-banner {
  background: var(--ion-color-tertiary) !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: fixed !important;
  bottom: 0;
  gap: 1rem;
  height: auto !important;
  min-height: 45px;

  .cookie-message {
    line-height: 1.2 !important;
    display: block;
    width: calc(100% - 1rem - 25px);
    padding: 1rem 0;
    font-size: 12px;
    margin: 0 !important;
  }

  .button-close {
    position: relative !important;
    top: 0 !important;
    right: 0 !important;
    margin: 0 !important;
    line-height: 1 !important;
    font-size: 20px !important;
    transform: rotate(45deg);
    padding: 0 !important;
    color: white !important;
    border: none !important;
    background: none !important;

    &::before {
      position: absolute;
      content: "";
      width: 25px;
      height: 25px;
      background: rgba(255, 255, 255, 0.3) !important;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

/* REGISTER STEPS */

.register-form {
  ion-input {
    height: 63px;
  }
}

.agreement {
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 11px !important;
  line-height: 16px !important;
  color: #63768b !important;
  width: 100%;

  a {
    font-size: 11px !important;
    line-height: 16px !important;
  }
}

form.register-form {
  padding: 10px;
}

.register-page-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  ion-grid {
    max-width: var(--max-element-size);
  }
}

.register-page-wrapper {
  .company-logo img {
    padding-top: 20px;
  }
}

.layout--phone ion-content.register-page-wrapper,
.layout--tablet ion-content.register-page-wrapper {
  --padding-start: 0;
  --padding-end: 0;
  --padding-top: 0px;
}

.layout--desktop ion-content.register-page-wrapper {
  ion-footer {
    ion-grid {
      padding: 0 20px;
    }

    &::before {
      display: none;
    }
  }

  .register-contractors-list,
  .register-contractor-validation-form,
  .register-eco-step {
    margin-bottom: 0;
  }
}

.password-hint-wrapper {
  border: 1px solid var(--ion-color-tertiary);
  border-radius: 10px;
  margin: 0 14px;
  position: relative;
  padding: 10px 36px 10px 20px;
  color: var(--ion-color-tertiary);

  &.invoice {
    margin: 10px 0 20px;
  }

  p {
    margin: 0;
  }

  ul {
    margin: 0;
    padding-left: 20px;
  }

  ion-icon {
    font-size: 20px;
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.contact-form {
  .password-hint-wrapper {
    margin: 0;
    padding: 10px 32px 10px 16px;
  }

  ion-label {
    margin: 10px 0 10px 4px;
    display: block;
  }
}

ion-footer.register-footer {
  ion-col {
    padding: 0;
  }

  ion-button {
    margin: 0;
    font-weight: 600;
    font-size: 12px;

    &[disabled] {
      --background: #dde1e7;
      --color: #63768b;
    }

    &[disabled="false"] {
      --background: var(--ion-color-primary);
      --color: white;
    }
  }
}

ion-item.furnizor-item,
ion-item.card-widget-list-item {
  ion-avatar[slot="start"] {
    margin: 0 20px 0 0;
  }

  ion-avatar[slot="end"] {
    margin: 0 0 0 10px;
    width: 20px;
    height: 20px;
  }

  ion-label {
    margin: 0;
  }
}

.register-contractors-list,
.register-contractor-validation-form,
.register-eco-step {
  margin-bottom: 91px;
}

.register-successful-contract-details {
  padding: 20px;
  border: 1px dashed #dde1e7;
  border-radius: 20px;

  ion-label.ag-detail {
    display: block;

    &:not(:first-of-type) {
      margin-top: 10px;
    }
  }
}

/* READINGS */

.readings-list {
  position: relative;

  .readings-list-icon-details {
    position: absolute;
    top: 0;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;

    ion-badge {
      border-radius: 8px;
      font-size: 10px;
      padding: 0 6px;
      line-height: 17px;
    }

    ion-icon {
      font-size: 18px;
      --ionicon-stroke-width: 50px;
    }
  }
}

.tab-menu-item-normal-phone {
  width: 48%;
}

ion-alert {
  .alert-wrapper {
    --width: calc(100% - 40px);
    --max-width: var(--max-element-size);
  }
}

.contract-tags {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: baseline;
  row-gap: 6px;
  margin-bottom: 10px;
}

.invoice-index-menu-wrapper {
  display: flex;
  justify-content: space-between;
}

/* INVOICES */

ion-list .card-widget-list-item.balance-sync {
  padding: 10px !important;
}

.invoice-view ion-label {
  span {
    display: block;
  }

  span.invoice-group-total {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-top: 5px;
    color: #f60b44;
    font-size: 13px !important;
    justify-content: space-between;

    &.green {
      color: var(--ion-color-primary);
    }

    &.blue {
      color: var(--ion-color-tertiary);
    }

    .small-device {
      display: none;
    }

    .large-device {
      display: inline;
    }

    @media screen and (max-width: 380px) {
      .large-device {
        display: none;
      }

      .small-device {
        display: inline;
      }
    }
  }
}

.invoice-flag {
  position: relative;
  border-radius: 5px;
  padding: 4px 8px 4px 26px;
  display: inline-block !important;
  margin-top: 6px !important;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    left: 8px;
    top: 9px;
  }

  &.processing {
    border: 1px solid var(--ion-color-primary);

    &::before {
      background: var(--ion-color-primary);
    }
  }

  &.pay-init {
    border: 1px solid var(--ion-color-warning);

    &::before {
      background: var(--ion-color-warning);
    }
  }
}

.invoice-legend-wrapper {
  ion-label {
    margin-right: 10px;
  }

  div {
    margin: 10px auto;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.contract-invoices-list {
  position: relative;

  &.invoice-details-view {
    padding: 10px 0 !important;
    margin-bottom: 10px !important;

    .invoice-actions {
      margin-right: 10px;
    }
  }

  .extra-spacing {
    height: 20px;
    width: 100%;
  }

  .invoice-flag {
    position: absolute;
    top: 10px;
    right: 20px;
    margin-top: 0 !important;
  }
}

.contract-header {
  margin-bottom: 10px;
  max-width: var(--max-element-size);

  .ag-h1 {
    line-height: 30px !important;
  }

  img {
    width: 80px;
  }

  ion-col.gray {
    margin-top: 10px;

    span {
      display: block;
      font-size: 15px;
    }
  }

  ion-row,
  ion-col {
    padding: 0 !important;
  }
}

.invoice-list {
  margin-top: 10px;

  ion-item {
    --padding-start: 10px;
  }
}

.invoice-checkbox {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.invoice-information {
  span.gray {
    font-weight: 500 !important;
  }
}

.invoice-information > span {
  display: block;
}

.invoice-actions,
.invoice-subtotals {
  font-size: 14px;
  display: flex;
  flex-direction: row;

  a {
    color: #007cef;
  }
}

.invoice-actions {
  justify-content: flex-end;
  gap: 20px;
}

.invoice-subtotals {
  justify-content: space-evenly;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 4px;
  color: var(--ion-color-primary);
  margin-bottom: 10px;
}

ion-button.invoice-pay-btn {
  &[disabled] {
    --background: #dde1e7;
    --color: #63768b;
  }

  &[disabled="false"] {
    --background: var(--ion-color-primary);
    --color: white;
  }
}

.card-widget {
  ion-list[lines="full"] {
    ion-item:not(:first-child) {
      margin-top: 10px;
    }

    ion-item:last-child {
      --border-width: 0;
    }
  }
}

.invoice-history-page {
  ion-avatar[slot="start"] {
    margin-right: 10px;
  }

  ion-item {
    --padding-start: 0;
    --inner-padding-end: 0;
  }

  ion-label[slot="end"] {
    margin: 0 0 0 10px;
  }

  ion-list {
    ion-item:first-of-type {
      .invoice-history-info-box {
        padding-top: 0;
      }
    }

    ion-item:last-of-type {
      --border-width: 0;
    }
  }

  .card-widget {
    ion-label.ion-text-center {
      margin-top: 10px;
    }
  }

  .invoice-subtotals {
    margin-bottom: 0;
  }
}

.invoice-history-info-box {
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
}

.invoice-history-inner-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.invoice-history-summary {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
}

.invoice-history-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  min-width: 76px;
}

.invoice-history-actions {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: stretch;
  font-size: 14px;
  gap: 10px;

  a {
    color: #007cef;
  }
}

ion-item:last-child.invoice-history-details-open {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

ion-item.invoice-history-details-open {
  --background: rgba(0, 0, 0, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.13);
  border-right: 1px solid rgba(0, 0, 0, 0.13);

  ion-item {
    --background: white;
  }

  ion-grid {
    width: 100%;
    padding-inline-start: 4px;
    padding-inline-end: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .card-widget {
    padding: 14px !important;
    border-radius: 14px !important;
  }
}

ion-list.payment-info-list {
  margin: 0 10px 10px;

  ion-item {
    --min-height: 24px;
  }
}

ion-item p {
  display: block;
  text-align: center;
  margin: 0 auto;
}

.payment-info-row {
  width: 100%;
  display: grid;
  grid-template-columns: minmax(66px, 1fr) minmax(66px, 1fr) 2fr;

  &.gray {
    span {
      font-size: 12px;
    }
  }

  span {
    display: block;
    place-self: center;
    text-align: center;
  }
}

.invoice-payment-modal {
  width: 100%;

  .invoice-payment-row {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.13);

    span {
      font-size: 12px;
      display: grid;
      grid-template-columns: minmax(110px, 40%) 1fr;
    }
  }

  .invoice-payment-phone-check {
    ion-input {
      margin-top: 10px;
      max-height: 44px;
    }
  }

  .invoice-payment-logos {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    img {
      height: 30px;
      width: auto;
    }
  }
}

.payment-result-mobile-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  gap: 10px;

  ion-icon {
    font-size: 20px;
  }

  a {
    color: black;
    text-decoration: none;
    font-weight: 600;
  }
}

.payment-result-tablet-btn {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 40px;
  gap: 10px;
  background: #f7f7f7;
  border-radius: 20px;
  padding: 10px 20px;
  width: 120px;

  ion-icon {
    font-size: 20px;
  }

  a {
    color: black;
    text-decoration: none;
    font-weight: 600;
    font-size: 16px;
  }
}

/* OTHERS MENU */

ion-content.others-page {
  --padding-top: 0px !important;
  --padding-bottom: 0px !important;
}

.others-menu {

  ion-label {
    margin: 0;
  }

  ion-item {
    --padding-start: 10px;
    --inner-padding-end: 10px;
    margin-bottom: 4px;

    &.next-is-divider {
      margin-bottom: 20px;

      & ~ ion-item {
        margin-top: 20px;
      }
    }
  }

  ion-avatar[slot="end"] {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  ion-label.ag-cta {
    font-size: 13.5px !important;
  }

  ion-item-divider {
    min-height: auto;
  }
}

.layout--tablet .others-menu,
.layout--desktop .others-menu {
  margin: 10px auto 0;

  ion-item {
    margin-bottom: 14px;

    &[lines="full"] {
      --padding-bottom: 30px;

      & ~ ion-item {
        margin-top: 30px;
      }
    }
  }
}

.others-icon-bg {
  width: 40px;
  height: 40px;
  background: #3880ff;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  &.demands-btn {
    background: var(--ion-color-primary);
  }


  &.logout-btn {
    background: #f7f7f7;

    ion-icon {
      color: var(--ion-color-danger);
    }
  }

  ion-icon {
    font-size: 24px;
    color: white;
  }
}

.help-page {
  box-sizing: border-box;

  ion-list {
    margin-top: 20px;
  }

  ion-avatar[slot="end"] {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}

.layout--phone {
  .help-page,
  ion-content.notifications-page {
    --padding-start: 20px;
    --padding-end: 20px;
    --padding-top: 20px;
    --padding-bottom: 20px;
  }
}

.help-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-page-content {
  height: calc(100vh - 56px - 90px - 70px);
}

.copyright {
  text-align: center;

  p {
    font-size: 10px;
    color: var(--ion-color-medium);
    margin: 0;
  }
}

.contact-form {
  margin-top: 10px;
}

.contact-recaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px auto;
}

.contact-disclaimer {
  padding: 10px;

  ion-label {
    font-weight: 400;
  }
}

.confirm-deletion {
  font-size: 16px;
  color: var(--ion-color-medium);
  line-height: 30px;
  padding: 20px 0;
  display: block;
}

.help-page-text-wrapper {
  h5 {
    font-size: 16px;
  }

  h6 {
    font-size: 14px;
    font-weight: 600;
  }

  p,
  li {
    font-size: 13px;
    line-height: 1.6;
    font-weight: 400;
  }

  a {
    font: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .help-last-update {
    font-size: 11px;
    color: var(--ion-color-medium);
  }

  .help-strong {
    font-weight: 600;
  }

  .help-block {
    display: block;
    margin-top: 6px;
  }

  ol,
  ul {
    padding-left: 20px;
  }

  ion-accordion {
    h6 {
      font-weight: 500;
      margin-top: 10px;
      color: #333;
      line-height: 1.3;
    }

    p,
    ul,
    li {
      width: 100% !important;
    }

    p,
    ul {
      display: block !important;
    }

    p,
    li {
      color: var(--ion-color-medium);
      text-align: left;
    }

    p {
      margin: 10px 0 !important;
    }

    ion-icon[slot="start"] {
      font-size: 20px;
      margin-right: 10px;
      color: black;
    }
  }

  ol.help-nested-list {
    counter-reset: item;

    li {
      display: block;

      &::before {
        content: counters(item, ".") ". ";
        counter-increment: item;
        font-weight: 600;
      }

      &:not(:last-of-type) {
        margin-bottom: 6px;
      }

      .definitions {
        display: block;
        margin-bottom: 6px;
        margin-left: 20px;

        label {
          font-weight: 600;
          font-size: 12px;
        }

        span {
          font-size: 12px !important;
        }
      }
    }

    ol {
      counter-reset: item;
      margin: 10px 0;

      li {
        span {
          font-size: 12px !important;
        }
      }
    }

    ul {
      margin: 10px 0;

      li {
        &::before {
          content: "▪ ";
        }
      }

      ul {
        margin: 6px 0;

        li {
          &::before {
            content: "▫ ";
          }
        }
      }
    }

    ul.local-law {
      margin: 10px 0;

      li {
        &::before {
          content: "";
        }
      }
    }
  }
}

.indentation {
  text-indent: 3rem;
}

.pointer-cursor {
  cursor: pointer;
}

/* OTHER PAGES */

.image-card-page {
  display: flex;
  justify-content: center;
  align-items: center;

  .aq-container-content {
    padding: 0;
    overflow: hidden;
  }

  .image-card-content {
    max-height: calc(100vh - 56px - 91px - 20px);

    img {
      width: 100%;
      position: relative;
      bottom: -10px;
    }
  }

  .image-card-details {
    padding: 20px;

    p {
      color: var(--ion-color-medium);
    }

    a > img {
      height: 40px;
      width: auto;
      margin-bottom: 10px;
    }

    ion-button {
      width: 200px;
      margin-bottom: 14px;

      &:last-child {
        margin-left: 10px;
      }

      ion-icon {
        font-size: 32px;
        margin-right: 10px;
      }
    }
  }
}

.layout--phone,
.maintenance-page {
  .image-card-page {
    .image-card-content {
      padding: 0;
      overflow: hidden;
      border: 1px solid #eff4fa;
      box-shadow: 0px 10px 18px -10px rgba(62, 87, 125, 0.06);
      border-radius: 20px;
    }

    .image-card-details {
      padding: 0 20px;
    }
  }
}