.gray {
  color: var(--ion-color-medium);
}

.black {
  color: var(--ion-color-light-contrast);
}

.gray, .black {
  text-align: left;
  width: 100%;
}