.pageTitle {
  margin-bottom: 20px;
  font-weight: 500;
  font-size: 18px;
}

.notificationCard.unread {
  .notificationText {
    color: var(--ion-color-light-contrast);

    .notificationSubject {
      font-weight: 600;
    }
  }
}

.notificationCard {
  border: 1px solid var(--ion-color-light-tint);
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  &.empty {
    margin-top: 10px;

    ion-text {
      font-size: 14px;
      text-align: center;
      display: block;
      width: 100%;
    }
  }

  .notificationIcon {
    width: 40px;
    height: 40px;
    background: rgba(var(--ion-color-primary-rgb), 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    ion-icon {
      font-size: 24px;
      color: var(--ion-color-primary);
    }
  }

  .notificationText {
    width: calc(100% - 40px - 24px - 40px);
    color: var(--ion-color-medium);

    &:hover {
      cursor: pointer;
    }

    p {
      margin: 0;
    }

    .notificationSubject {
      font-size: 15px;
      font-weight: 400;
    }

    .notificationContent {
      font-size: 13px;
      font-weight: 300;
      margin: 6px 0;
    }

    .notificationCreatedDate {
      font-size: 11px;
      font-weight: 300;
    }
  }

  .notificationDelete {
    width: 40px;
    height: 40px;
    background: rgba(var(--ion-color-danger-rgb), 0.1);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    ion-icon {
      font-size: 22px;
      color: var(--ion-color-danger);
    }
  }
}