.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  & > ion-button {
    --border-radius: 25px;
    --padding-end: 20px;
    --padding-start: 20px;
    width: 100%;
    height: 50px;
    text-align: left;

    &:not([color="tertiary"]) {
      --background: var(--ion-color-light-tint);
      --background-hover: var(--ion-color-light);
      --background-hover-opacity: 0.2;
      --background-activated: var(--ion-color-light);
      --color: var(--ion-color-light-contrast);
    }

    & > ion-text {
      width: 100%;
      font-weight: 600;
      font-size: 14px;
      text-align: left;
    }
  }
}

.menu.mobile {
  flex-direction: row;

  @media screen and (max-width: 400px) {
    gap: 10px;
  }
  @media screen and (max-width: 340px) {
    gap: 4px;
  }

  & > ion-button {

    @media screen and (min-width: 400px) {
      width: 50%;
    }

    @media screen and (max-width: 400px) {
      --padding-end: 10px;
      --padding-start: 10px;
    }

    & > ion-text {
      text-align: center;
    }

    &:not([color="tertiary"]) {
      --background: transparent;
      --background-activated: var(--ion-color-light-tint);
    }
  }
}

.deleteBtn {
  background: var(--ion-color-light);
  width: 98%;
}