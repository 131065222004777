.updateAppLinks {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;

  img {
    height: 46px;
    width: auto;
    @media screen and (max-width: 400px) {
      height: 36px;
    }
  }
}